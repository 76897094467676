<template>
  <div
    id="kt_header_mobile"
    class="header-mobile align-items-center"
    :class="headerClasses"
  >
    <!--begin::Logo-->
    <a href="/">
      <img
        alt="Logo"
        :src="headerLogo"
      >
    </a>
    <!--end::Logo-->
    <!--begin::Toolbar-->
    <div class="d-flex align-items-center">
      <!--begin::Aside Mobile Toggle-->
      <button
        v-if="asideEnabled"
        id="kt_aside_mobile_toggle"
        class="btn p-0 burger-icon burger-icon-left"
      >
        <span />
      </button>
      <!--end::Aside Mobile Toggle-->
      <!--begin::Topbar Mobile Toggle-->
      <b-dropdown
          id="kt_header_mobile_topbar_toggle"
          class="pl-2"
          variant="link"
          toggle-class="btn btn-hover-text-primary p-0 ml-2 "
          ref="kt_header_mobile_topbar_toggle" no-caret>
        <template #button-content>
          <span class="svg-icon svg-icon-xl">
            <!--begin::Svg Icon | path:svg/icons/General/User.svg-->
            <inline-svg
                class="svg-icon"
                src="/media/svg/icons/General/User.svg"
            />
            <!--end::Svg Icon-->
          </span>
        </template>
        <b-dropdown-item @click="onLogout">Sair</b-dropdown-item>
      </b-dropdown>
      <!--end::Topbar Mobile Toggle-->
    </div>
    <!--end::Toolbar-->
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

import KTLayoutHeaderTopbar from '@/assets/js/layout/base/header-topbar.js'
import { LOGOUT } from '@/core/services/store/auth.module'

export default {
  name: 'KTHeaderMobile',
  components: {},
  computed: {
    ...mapGetters(['layoutConfig', 'getClasses']),

    /**
     * Get header logo
     * @returns {string}
     */
    headerLogo () {
      return process.env.BASE_URL + this.layoutConfig('self.logo.dark')
    },

    /**
     * Get classes for mobile header
     * @returns {null|*}
     */
    headerClasses () {
      const classes = this.getClasses('header_mobile')

      if (typeof classes !== 'undefined') {
        return classes.join(' ')
      }

      return null
    },

    /**
     * Check if the left aside menu is enabled
     * @returns {boolean}
     */
    asideEnabled () {
      return !!this.layoutConfig('aside.self.display')
    },
  },
  methods: {
    onLogout () {
      this.$store
        .dispatch(LOGOUT)
        .then(() => this.$router.push({ name: 'login' }))
    },
  },
}
</script>
