<template>
  <div>
    <div v-if="loading" class="text-center vh-100 d-flex align-items-center">
      <v-progress-circular :size="50" :width="3" indeterminate style="color: #fff; margin: 0 auto"/>
    </div>
    <ul class="menu-nav mt-5">
      <template v-for="(menu_item, mIdx) in menu">
        <li v-if="menu_item.link === ''" :key="mIdx" class="menu-section">
          <h4 class="menu-text">
            {{ menu_item.name }}
          </h4>
          <i class="menu-icon flaticon-more-v2" />
        </li>
        <router-link
          v-else
          :key="mIdx"
          v-slot="{ href, navigate, isActive, isExactActive }"
          :to="menu_item.link"
          custom
        >
          <li
            aria-haspopup="true"
            data-menu-toggle="hover"
            class="menu-item"
            :class="[
              isActive && 'menu-item-active',
              isExactActive && 'menu-item-active',
            ]"
          >
            <a
                :href="href"
                class="menu-link"
            >
              <i v-if="menu_item.icon !== ''" class="menu-icon" :class="menu_item.icon"/>
              <span class="menu-text">{{ menu_item.name }}</span>
            </a>
          </li>
        </router-link>
        <li
          v-for="(submenu_item, sIdx) in menu_item.children"
          :key="mIdx.toString()+sIdx.toString()"
          v-bind="submenu_item"
          aria-haspopup="true"
          data-menu-toggle="hover"
          class="menu-item menu-item-submenu"
          :class="{ 'menu-item-open': hasActiveChildren(submenu_item.open) }"
        >
          <a href="#" class="menu-link menu-toggle">
            <i class="menu-icon" :class="submenu_item.icon" />
            <span class="menu-text"> {{ submenu_item.name }} </span>
            <i class="menu-arrow" />
          </a>
          <div class="menu-submenu">
            <span class="menu-arrow" />
            <ul class="menu-subnav">
              <li aria-haspopup="true" class="menu-item menu-item-parent">
                <span class="menu-link">
                  <span class="menu-text">{{ submenu_item.name }}</span>
                </span>
              </li>
              <router-link
                v-for="(link_item, lIdx) in submenu_item.children"
                :key="sIdx.toString()+lIdx.toString()"
                v-slot="{ href, navigate, isActive, isExactActive }"
                :to="link_item.link"
                custom
              >
                <li
                  aria-haspopup="true"
                  data-menu-toggle="hover"
                  class="menu-item"
                  :class="[
                    isActive && 'menu-item-active',
                    isExactActive && 'menu-item-active',
                  ]"
                >
                  <a
                      :href="
                        href.indexOf('/login/legacy-app') > -1
                          ? newUrl + href + '&token=' + StorageService.getToken()
                          : href
                      "
                      class="menu-link"
                  >
                    <i class="menu-bullet menu-bullet-dot" />
                    <span class="menu-text">{{ link_item.name }}</span>
                  </a>
                </li>
              </router-link>
            </ul>
          </div>
        </li>
      </template>
    </ul>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

import { getUser } from '@/core/services/storage.service'
import StorageService from '@/core/services/storage.service'

export default {
  name: 'KTMenu',
  data: () => ({
    menu: [],
    loading: false,
  }),
  computed: {
    ...mapGetters(['currentUserPersonalInfo']),
    StorageService () {
      return StorageService
    },
    newUrl () {
      return process.env.VUE_APP_NEW_URL
    },
  },
  watch: {},
  mounted: function () {
    this.$nextTick(() => this.generateMenu())
  },
  methods: {
    hasActiveChildren (match) {
      return this.$route.path.indexOf(match) === 1
    },

    async generateMenu () {
      const removeItemsWithInvalidGate = function (arr, validGates) {
        return arr.map((item) => {
          if (item.children && item.children.length > 0) {
            // verifica até o último nível
            item.children = removeItemsWithInvalidGate(item.children, validGates)

            // remove submenu, caso este não tenha items liberados
            if (item.children.length === 0) {
              return null
            }
          }

          if (!item.gate || validGates.includes(item.gate)) {
            return item
          }
        }).filter(Boolean) // Filtrar para remover valores nulos
      }

      this.menu = await removeItemsWithInvalidGate(require('./menu.json'), getUser().permissoes)
    },
  },
}
</script>
