<template>
  <!-- begin::Page loader -->
  <div class="page-loader page-loader-logo page-loader-non-block">
    <img
      alt="Logo"
      :src="logo"
      width="200"
    >
    <div
      class="spinner"
      :class="spinnerClass || 'spinner-primary'"
    />
  </div>
  <!-- end::Page Loader -->
</template>

<script>
export default {
  name: 'Loader',
  props: {
    logo: {
      type: String,
      default: '',
    },
    spinnerClass: {
      type: String,
      default: '',
    },
  },
}
</script>
