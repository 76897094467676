<template>
  <!-- begin:: Footer -->
  <div
    id="kt_footer"
    class="footer bg-white py-4 d-flex flex-lg-column"
  >
    <div
      v-if="info"
      class="d-flex align-items-center justify-content-between"
      :class="{ 'container-fluid': widthFluid, container: !widthFluid }"
    >
      <div class="text-grey font-weight-medium">
        Copyright &copy {{ new Date().getFullYear() }}, {{ (info.owner) }}.
      </div>
      <div class="font-weight-medium">
        <a
            v-if="auth.hasPermission('tela.exibir.release.notes')"
            v-bind:href="'/release-notes'"
        >{{ info.tag_name }} | {{ info.published_at | date }}</a>
        <span v-else>{{ info.tag_name }} | {{ info.published_at | date }}</span>

      </div>
    </div>
  </div>
  <!-- end:: Footer -->
</template>

<script>
import { mapGetters } from 'vuex'
import apiService from "@/core/services/api.service";
import {auth} from "@/core/functions/auth";

export default {
  name: 'KTFooter',

  data () {
    return {
      info: null
    }
  },

  computed: {
    auth() {
      return auth
    },
    ...mapGetters(['layoutConfig']),

    /**
     * Check if footer container is fluid
     */
    widthFluid () {
      return this.layoutConfig('footer.width') === 'fluid'
    },
  },

  mounted () {
    this.init()
  },

  methods: {
    async init () {
      this.info = (await apiService._get('info')).data
    }
  },
}
</script>
