import { VProgressCircular } from 'vuetify/lib/components/VProgressCircular';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(_vm.loading)?_c('div',{staticClass:"text-center vh-100 d-flex align-items-center"},[_c(VProgressCircular,{staticStyle:{"color":"#fff","margin":"0 auto"},attrs:{"size":50,"width":3,"indeterminate":""}})],1):_vm._e(),_c('ul',{staticClass:"menu-nav mt-5"},[_vm._l((_vm.menu),function(menu_item,mIdx){return [(menu_item.link === '')?_c('li',{key:mIdx,staticClass:"menu-section"},[_c('h4',{staticClass:"menu-text"},[_vm._v(" "+_vm._s(menu_item.name)+" ")]),_c('i',{staticClass:"menu-icon flaticon-more-v2"})]):_c('router-link',{key:mIdx,attrs:{"to":menu_item.link,"custom":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var href = ref.href;
var navigate = ref.navigate;
var isActive = ref.isActive;
var isExactActive = ref.isExactActive;
return [_c('li',{staticClass:"menu-item",class:[
            isActive && 'menu-item-active',
            isExactActive && 'menu-item-active' ],attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"menu-link",attrs:{"href":href}},[(menu_item.icon !== '')?_c('i',{staticClass:"menu-icon",class:menu_item.icon}):_vm._e(),_c('span',{staticClass:"menu-text"},[_vm._v(_vm._s(menu_item.name))])])])]}}],null,true)}),_vm._l((menu_item.children),function(submenu_item,sIdx){return _c('li',_vm._b({key:mIdx.toString()+sIdx.toString(),staticClass:"menu-item menu-item-submenu",class:{ 'menu-item-open': _vm.hasActiveChildren(submenu_item.open) },attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},'li',submenu_item,false),[_c('a',{staticClass:"menu-link menu-toggle",attrs:{"href":"#"}},[_c('i',{staticClass:"menu-icon",class:submenu_item.icon}),_c('span',{staticClass:"menu-text"},[_vm._v(" "+_vm._s(submenu_item.name)+" ")]),_c('i',{staticClass:"menu-arrow"})]),_c('div',{staticClass:"menu-submenu"},[_c('span',{staticClass:"menu-arrow"}),_c('ul',{staticClass:"menu-subnav"},[_c('li',{staticClass:"menu-item menu-item-parent",attrs:{"aria-haspopup":"true"}},[_c('span',{staticClass:"menu-link"},[_c('span',{staticClass:"menu-text"},[_vm._v(_vm._s(submenu_item.name))])])]),_vm._l((submenu_item.children),function(link_item,lIdx){return _c('router-link',{key:sIdx.toString()+lIdx.toString(),attrs:{"to":link_item.link,"custom":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
          var href = ref.href;
          var navigate = ref.navigate;
          var isActive = ref.isActive;
          var isExactActive = ref.isExactActive;
return [_c('li',{staticClass:"menu-item",class:[
                  isActive && 'menu-item-active',
                  isExactActive && 'menu-item-active' ],attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"menu-link",attrs:{"href":href.indexOf('/login/legacy-app') > -1
                        ? _vm.newUrl + href + '&token=' + _vm.StorageService.getToken()
                        : href}},[_c('i',{staticClass:"menu-bullet menu-bullet-dot"}),_c('span',{staticClass:"menu-text"},[_vm._v(_vm._s(link_item.name))])])])]}}],null,true)})})],2)])])})]})],2)])}
var staticRenderFns = []

export { render, staticRenderFns }