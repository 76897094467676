<template>
  <div class="topbar-item">
    <div class="w-auto d-flex align-items-center px-2">
      <span class="text-muted font-weight-bold font-size-base d-none d-lg-inline mr-1">
        Olá,
      </span>
      <span class="text-dark-50 font-weight-bolder font-size-base d-none d-lg-inline mr-3">
        {{ currentUserPersonalInfo.name }}
      </span>
      <span
          class="ma-1 d-lg-none fas fa-filter"
          outlined
          color="indigo"
          @click="onOpenFilter"
      >
      </span>
      <v-btn
        class="ma-2 d-none d-lg-block"
        outlined
        color="indigo"
        @click="onLogout"
      >
      Sair
    </v-btn>
    </div>
  </div>
</template>

<script>

import { mapGetters } from 'vuex'

import { LOGOUT } from '@/core/services/store/auth.module'
import { OPEN_FILTER } from '@/core/services/store/filter.module'
import ApiService from "@/core/services/api.service";

export default {
  name: 'KTQuickUser',
  data () {
    return {
    }
  },
  computed: {
    ...mapGetters(['currentUserPersonalInfo']),
  },
  mounted () {
    // Init Quick User Panel
  },
  methods: {
    onOpenFilter () {
      this.$store
        .dispatch(OPEN_FILTER)
    },
    onLogout () {
      ApiService.post('auth/logout')

      this.$store
        .dispatch(LOGOUT)
        .then(() => this.$router.push({ name: 'login' }))
    },
  },
}
</script>
